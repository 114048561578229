// create an intersection observer for the elements .sticky.top-0 and add the class .is-pinned to an element that is currently pinned

let init = false;

const isStickyPinned = (entry: IntersectionObserverEntry) => entry.intersectionRatio < 1;

const intersectionObserverCallback: IntersectionObserverCallback = (entries) =>
  entries.forEach((entry) => entry.target.classList.toggle('is-pinned', isStickyPinned(entry)));

const setup = () => {
  if (init) {
    return;
  }
  const elements = document.querySelectorAll('.sticky.top-\\[-1px\\]');

  if (!elements) {
    return;
  }

  const observer = new IntersectionObserver(intersectionObserverCallback, { threshold: [1] });

  elements.forEach((element) => {
    observer.observe(element);
  });

  init = true;
};

setup();
